// icons
import ALPHA_ICON from '../../images/Token/ALPHA_128.png';
import BASE_ICON from '../../images/Token/LBUNC_128.png';
import BOYS_ICON from '../../images/Token/BOYS_128.png';
import CATWIFHAT_ICON from '../../images/Token/CATWIFHAT_128.png';
import COOKIE_ICON from '../../images/Token/COOKIE_128.png';
import DEATH_ICON from '../../images/Token/DEATH_128.png';
import EAGLE_ICON from '../../images/Token/EAGLE_128.png';
import FOOD_ICON from '../../images/Token/FOOD_128.png';
import FRG_ICON from '../../images/Token/FRG_128.png';
import FROG_ICON from '../../images/Token/FROG_128.png';
import GRDX_ICON from '../../images/Token/GRDX_128.png';
import HMEME_ICON from '../../images/Token/HMEME_128.png';
import JURIS_ICON from '../../images/Token/JURIS_128.png';
import LCW_ICON from '../../images/Token/LCW_128.png';
import LNC_ICON from '../../images/Token/LNC_128.png';
import LUNW_ICON from '../../images/Token/LUNW_128.png';
import MBA_ICON from '../../images/Token/MBA_128.png';
import PIZZAB_ICON from '../../images/Token/PIZZAB_128.png';
import ROCKET_ICON from '../../images/Token/ROCKET_128.png';
import ROTTI_ICON from '../../images/Token/ROTTI_128.png';
import SON_ICON from '../../images/Token/SON_128.png';
import TERRA_ICON from '../../images/Token/TERRA_128.png';
import TKMN_ICON from '../../images/Token/TKMN_128.png';
import TRIT_ICON from '../../images/Token/TRIT_128.png';

// coins_tokens
export const LUNA = "uluna"
export const cwLUNA = "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6"
export const BASE = "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m"

// contract address
export const BINDER_ADDR = "terra1uskz68jrapecu87fp0ay3yq9luyespvcufnssrn48dk5arnx3mhqjm7wlt"

export const PAIRS = {
    market1: {
      market: "ALPHA-cwLUNC",
      b_icon: ALPHA_ICON,
      b_symbol: "ALPHA",
      b_address: "terra1x6e64es6yhauhvs3prvpdg2gkqdtfru840wgnhs935x8axr7zxkqzysuxz",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra12u7khzrzn05a73xkpq6a5zrcazz2xmqn7lvupmqmca06pgcyt5qsa9e7p6", // terraport pool
      tableKey: "terra1x6e64es6yhauhvs3prvpdg2gkqdtfru840wgnhs935x8axr7zxkqzysuxz-cwlunc",
    },
    market2: {
      market: "BASE-cwLUNC",
      b_icon: BASE_ICON,
      b_symbol: "BASE",
      b_address: "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "tbc", //tbc or xyk
      tokenPool: "", // not used for tbc
      tableKey: "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m-cwlunc",
    },
    market3: {
      market: "BOYS-cwLUNC",
      b_icon: BOYS_ICON,
      b_symbol: "BOYS",
      b_address: "terra1a4x7t4w7a5hwqph0q0m5maqu45j99cpkgt82rllvrh4hc6g53k7spulsqj",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra16ma4mvepnpdpjd6tumfszx5jud825skdfvpxeyc4q0zlqxcuhlgqrnqxeq", // terraport pool
      tableKey: "terra1a4x7t4w7a5hwqph0q0m5maqu45j99cpkgt82rllvrh4hc6g53k7spulsqj-cwlunc",
    },
    market4: {
      market: "CATWIFHAT-cwLUNC",
      b_icon: CATWIFHAT_ICON,
      b_symbol: "CATWIFHAT",
      b_address: "terra1m58hc296srr6xygrrfyu6u0e32pl8d459nfs55pmegke8zlv94rqsjz882",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1han5md6ha92ntulv7c5zzh56q9ncj9vc9k53myyu2rmnqq2vpseqawmjh0", // terraport pool
      tableKey: "terra1m58hc296srr6xygrrfyu6u0e32pl8d459nfs55pmegke8zlv94rqsjz882-cwlunc",
    },
    market5: {
      market: "COOKIE-cwLUNC",
      b_icon: COOKIE_ICON,
      b_symbol: "COOKIE",
      b_address: "terra15p8su45k45axng8ue59rl6zph4at27s49u3agr6uqrx3dhcxpg3qt0ekdt",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1x5ygs7pk6x0arh4kpfk5rvyx94ukyjs7xvvcyf8umqpkwqqnucas03f7su", // terraport pool
      tableKey: "terra15p8su45k45axng8ue59rl6zph4at27s49u3agr6uqrx3dhcxpg3qt0ekdt-cwlunc",
    },
    market6: {
      market: "DEATH-cwLUNC",
      b_icon: DEATH_ICON,
      b_symbol: "DTH",
      b_address: "terra1w6nns79ckrpmj8pue3ydatjggnfvw33vgse9pyp28payslxs8ahqj6ytqt",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1khk833u6hxa2s57pl3lcsax59crn4u37zxuraf3ag99cylje804s75mwq8", // terraport pool
      tableKey: "terra1w6nns79ckrpmj8pue3ydatjggnfvw33vgse9pyp28payslxs8ahqj6ytqt-cwlunc",
    },
    market7: {
      market: "EAGLE-cwLUNC",
      b_icon: EAGLE_ICON,
      b_symbol: "EAGLE",
      b_address: "terra1q2au2v9gg0yxarqr2pufusymskzpzndmrdv3yke7erd2lh3cjfuqg47rhx",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1338e5xp2jdza72k9m9hg239zhj2pk3wh6kxuh7m3pmj5nm07sses2c2v20", // terraport pool
      tableKey: "terra1q2au2v9gg0yxarqr2pufusymskzpzndmrdv3yke7erd2lh3cjfuqg47rhx-cwlunc",
    },
    market8: {
      market: "FOOD-cwLUNC",
      b_icon: FOOD_ICON,
      b_symbol: "FOOD",
      b_symbol: "FOOD",
      b_address: "terra15gjgmln8xj72rf69tx2zlrtcjzzvkcrdvrvfnmxja6kacz07pg0ssflecz",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1t8sgl07tw05sgfg5t4a5xuhsqxfw3m65gajwr0su2nlpnh728peqj76aww", // terraport pool
      tableKey: "terra15gjgmln8xj72rf69tx2zlrtcjzzvkcrdvrvfnmxja6kacz07pg0ssflecz-cwlunc",
    },
    market9: {
      market: "FRG-cwLUNC",
      b_icon: FRG_ICON,
      b_symbol: "FRG",
      b_address: "terra14rfnhc2aru7jp304lht3yf903necsnrt4cxd464cftsfcksw0rtsgundnt",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1ht0fuwnurs5afln8u36nkjsdy4zcmuv3hnre54rgy2lf9286fxtqsaf4yu", // terraport pool
      tableKey: "terra14rfnhc2aru7jp304lht3yf903necsnrt4cxd464cftsfcksw0rtsgundnt-cwlunc",
    },
    market10: {
      market: "FROG-cwLUNC",
      b_icon: FROG_ICON,
      b_symbol: "FROG",
      b_address: "terra1wez9puj43v4s25vrex7cv3ut3w75w4h6j5e537sujyuxj0r5ne2qp9uwl9",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "tbc", //tbc or xyk
      tokenPool: "", // not used for tbc
      tableKey: "terra1wez9puj43v4s25vrex7cv3ut3w75w4h6j5e537sujyuxj0r5ne2qp9uwl9-cwlunc",
    },
    market11: {
      market: "GRDX-cwLUNC",
      b_icon: GRDX_ICON,
      b_symbol: "GRDX",
      b_address: "terra12f3f5fzfzxckc0qlv3rmwwkjfhzevpwmx77345n0zuu2678vxf0sm6vvcw",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra12t88hh2nhasfzflgs6efl3ejhwedjnhfajstcku68dk2sxlv9yasz5f0a6", // terraport pool
      tableKey: "terra12f3f5fzfzxckc0qlv3rmwwkjfhzevpwmx77345n0zuu2678vxf0sm6vvcw-cwlunc",
    },
    market12: {
      market: "HMEME-cwLUNC",
      b_icon: HMEME_ICON,
      b_symbol: "HMEME",
      b_address: "terra1jy0qqp7nyj5uz34t0w5yav006lak4zgspa6d89c0jlzy7zhknpsqz0q9vs",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1l0zz4s97ljlphguu0082d34zzh434vrlg7de7k4m27lpdjhf2sxs2sluls", // terraport pool
      tableKey: "terra1jy0qqp7nyj5uz34t0w5yav006lak4zgspa6d89c0jlzy7zhknpsqz0q9vs-cwlunc",
    },
    market13: {
      market: "JURIS-cwLUNC",
      b_icon: JURIS_ICON,
      b_symbol: "JURIS",
      b_address: "terra1vhgq25vwuhdhn9xjll0rhl2s67jzw78a4g2t78y5kz89q9lsdskq2pxcj2",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra13w6cruzc0xkdhpmrnspr5j282x3wyx0m4f6kjnr65tpq63usn8eqp5emy4", // terraport pool
      tableKey: "terra1vhgq25vwuhdhn9xjll0rhl2s67jzw78a4g2t78y5kz89q9lsdskq2pxcj2-cwlunc",
    },
    market14: {
      market: "LCW-cwLUNC",
      b_icon: LCW_ICON,
      b_symbol: "LCW",
      b_address: "terra1wx48f5g06l9qxw8k4gn3200lmseu85vkgangwyn9xdu66mtfsfxq60hkdt",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra13mg8pvsrhutgw06khvq43pcuhra8cwmxqftlfpxscfqlwxt6yxuqdtqp2f", // terraport pool
      tableKey: "terra1wx48f5g06l9qxw8k4gn3200lmseu85vkgangwyn9xdu66mtfsfxq60hkdt-cwlunc",
    },
    market15: {
      market: "LNC-cwLUNC",
      b_icon: LNC_ICON,
      b_symbol: "LNC",
      b_address: "terra1nuwdr5zaul2gnq88xapq8ythmaw4092kt8lxsl6jd36h47u8vpcqnrmdcm",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra14qz98qdq4rk8d3sm0kjn9zsf6c3yfc3azyemc5j7s85t92cl0ulsvwc9d9", // terraport pool
      tableKey: "terra1nuwdr5zaul2gnq88xapq8ythmaw4092kt8lxsl6jd36h47u8vpcqnrmdcm-cwlunc",
    },
    market16: {
      market: "LUNW-cwLUNC",
      b_icon: LUNW_ICON,
      b_symbol: "LUNW",
      b_address: "terra1j9q30fspe7tn0nrxpjfnzzdha4xfkyznluwnqrgxshtyv2tfe5kswnrj6c",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1xrt4m9xexy64vqt3k96k4ng74gqw5lv5mcramqdkl3ee48cf4edqxjq475", // terraport pool
      tableKey: "terra1j9q30fspe7tn0nrxpjfnzzdha4xfkyznluwnqrgxshtyv2tfe5kswnrj6c-cwlunc",
    },
    market17: {
      market: "MBA-cwLUNC",
      b_icon: MBA_ICON,
      b_symbol: "MBA",
      b_address: "terra1sx83yfywj6esksv4gj85qc5jkk4dvs20d2xe02yfqpxq3np34jvqgzu4f4",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1u9t44rwldu8ach2njqu3ppkfwc9s2g9kz54h0nr3dkzp2qx39ptsjchl5g", // terraport pool
      tableKey: "terra1sx83yfywj6esksv4gj85qc5jkk4dvs20d2xe02yfqpxq3np34jvqgzu4f4-cwlunc",
    },
    market18: {
      market: "PIZZAB-cwLUNC",
      b_icon: PIZZAB_ICON,
      b_symbol: "PIZZAB",
      b_address: "terra1vxhnzlr44ajzq84xra07gtvyv5saxmsyz6cd5cewqm479znvsepqzdkk05",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra13dmjw2dpj49nnazxq79p6uyfa9jd5rrte9tvcvqywmfaphvsfndqsre727", // terraport pool
      tableKey: "terra1vxhnzlr44ajzq84xra07gtvyv5saxmsyz6cd5cewqm479znvsepqzdkk05-cwlunc",
    },
    market19: {
      market: "ROCKET-cwLUNC",
      b_icon: ROCKET_ICON,
      b_symbol: "ROCKET",
      b_address: "terra1jedse4gvm5p3gnt0sm5gn8zr8zsz3zf73985csr8xh8jv7nz299q26elnf",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra17lveswhf4gk4l43k33zlz8j3r7kgdsp0tsmj5zunnuchsq547rmqqdr4j7", // terraport pool
      tableKey: "terra1jedse4gvm5p3gnt0sm5gn8zr8zsz3zf73985csr8xh8jv7nz299q26elnf-cwlunc",
    },
    market20: {
      market: "ROTTI-cwLUNC",
      b_icon: ROTTI_ICON,
      b_symbol: "ROTTI",
      b_address: "terra12j3xuxx52cg045qk37ee4k4u4fsgvyuf8d89dh7c9mr706jvxdascahqej",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1u5tl9hf3q5uhtch5xan5rkf0w9awzn0klwffsphupykafm52n6jqr3ns8y", // terraport pool
      tableKey: "terra12j3xuxx52cg045qk37ee4k4u4fsgvyuf8d89dh7c9mr706jvxdascahqej-cwlunc",
    },
    market21: {
      market: "SON-cwLUNC",
      b_icon: SON_ICON,
      b_symbol: "SON",
      b_address: "terra10sfn80av99qkcx3rdpm4rt9hsguadyra5ewyxr",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1vz6d0lzfak0ef42hfm38erctztymcrsj5kww0v4z8rsaqvwgezwqy3u4xx", // terraport pool
      tableKey: "terra10sfn80av99qkcx3rdpm4rt9hsguadyra5ewyxr-cwlunc",
    },
    market22: {
      market: "TERRA-cwLUNC",
      b_icon: TERRA_ICON,
      b_symbol: "TERRA",
      b_address: "terra1ex0hjv3wurhj4wgup4jzlzaqj4av6xqd8le4etml7rg9rs207y4s8cdvrp",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1rlfns43umzqszm52txxmnseevffx2pe408c99m7cnvd828tdj67q9ftjs2", // terraport pool
      tableKey: "terra1ex0hjv3wurhj4wgup4jzlzaqj4av6xqd8le4etml7rg9rs207y4s8cdvrp-cwlunc",
    },
    market23: {
      market: "TKMN-cwLUNC",
      b_icon: TKMN_ICON,
      b_symbol: "TKMN",
      b_address: "terra1nv36kq4dpxf4d00w4mv4w095ek8kv48f54v46qduruy4fwd2dtzq2uk70j",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1t9eleddtsrndxgjcu8n0vn0zne6tlar8n8r605p9089nkwj9v0js2xcun4", // terraport pool
      tableKey: "terra1nv36kq4dpxf4d00w4mv4w095ek8kv48f54v46qduruy4fwd2dtzq2uk70j-cwlunc",
    },
    market24: {
      market: "TRIT-cwLUNC",
      b_icon: TRIT_ICON,
      b_symbol: "TRIT",
      b_address: "terra1g6fm3yu79gv0rc8067n2nnfpf0vks6n0wpzaf4u7w48tdrmj98zsy7uu00",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "terra1suu8wggkc6utp3zkya58v6chfjp2ppx37ufzz0p8ynl7v9zzrpsswucxmp", // terraport pool
      tableKey: "terra1g6fm3yu79gv0rc8067n2nnfpf0vks6n0wpzaf4u7w48tdrmj98zsy7uu00-cwlunc",
    },




    /*
    market_: {
      market: "-cwLUNC",
      b_icon: _ICON,
      b_symbol: "",
      b_address: "",
      q_symbol: "cwLUNC",
      q_address: "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
      tokenType: "xyk", //tbc or xyk
      tokenPool: "", // terraport pool
      tableKey: "-cwlunc",
    },
    */

  };
  